@import '../../styles/variables';
@import '../../styles/typography';

.chat-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: 8px;

  &__avatar {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  &__header {
    padding: 10px 16px;
    border-bottom: 1px solid $black--8;
  }

  &__wrapper {
    padding: 8px 0;
  }

  &__title {
    @include typo-h2;
  }

  &__body {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__message-list {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    overflow: hidden;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 24px 16px;
  }
}
