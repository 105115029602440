@import '../../../styles/variables';
@import '../../../styles/typography';

@mixin common-btn-style {
  padding: var(--bare-padding, var(--btn-padding));
  border-radius: 80px;
}

@mixin default-btn-style {
  --btn-padding: 10px 16px;

  min-width: 80px;
  color: $white;

  @include typo-h3;
}

.ts-button {
  /* Reset style */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  --btn-primary-color: #{$primary-color};

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: var(--btn-bg-color, transparent);
  white-space: nowrap;
  cursor: pointer;

  /* For <a> tag link */
  text-decoration: none;
  text-align: center;

  @include common-btn-style;

  /* .focus-visible - полифил, используемый вместо псевдокласса :focus-visible */
  &:hover,
  &.focus-visible,
  &:active,
  &--active {
    background-image: linear-gradient(var(--btn-event-color) 0%, var(--btn-event-color) 100%, transparent);
    outline: none;
  }

  &[disabled] {
    background-color: var(--btn-disabled-color, transparent);
    color: $dark--30;
    pointer-events: none;
    cursor: default;
  }

  /* ---- Primary button ---- */
  &--primary {
    @include default-btn-style;

    --btn-bg-color: var(--btn-primary-color);
    --btn-event-color: #{rgba($black, var(--opacity-count))};

    &:hover,
    &.ts-button--active {
      --opacity-count: 0.16;
    }

    &.focus-visible {
      --opacity-count: 0.2;
    }

    &:active {
      --opacity-count: 0.24;
    }

    &[disabled] {
      --btn-disabled-color: #{$black--5};
    }
  }

  /* ---- Secondary button ---- */
  &--secondary {
    @include default-btn-style;

    --opacity-count: 0.08;
    --btn-bg-color: #{rgba(var(--btn-rgb-color, $primary-color-rgb), var(--opacity-count))};

    color: var(--btn-primary-color);

    &:hover,
    &.ts-button--active {
      --opacity-count: 0.16;
    }

    &.focus-visible {
      --opacity-count: 0.2;
    }

    &:active {
      --opacity-count: 0.24;
    }

    &[disabled] {
      --btn-disabled-color: #{$black--5};
    }
  }

  /* ---- Text button ---- */
  &--text {
    @include default-btn-style;

    --btn-event-color: #{rgba(var(--btn-rgb-color, $primary-color-rgb), var(--opacity-count))};

    color: var(--btn-primary-color);

    &:hover,
    &.ts-button--active {
      --opacity-count: 0.08;
    }

    &.focus-visible {
      --opacity-count: 0.12;
    }

    &:active {
      --opacity-count: 0.16;
    }
  }

  /* ---- Dark button ---- */
  &--dark {
    --btn-primary-color: #{$dark--70};

    &:not(.ts-button--primary, .ts-button--bare) {
      --btn-event-color: #{rgba($dark, var(--opacity-count, 1))};
    }

    &.ts-button--secondary {
      --btn-bg-color: #{rgba($dark--70, var(--opacity-count))};
    }

    &.ts-button--bare {
      --bare-handler: #{$dark--50};
    }
  }

  /* Base style for icon button */
  &--only-icon {
    --btn-padding: 8px;

    min-width: auto;
    border-radius: 50%;
    text-align: center;
  }

  /* Base style for button with icon or loader */
  &--with-icon {
    --btn-padding: 8px 16px;

    margin-right: 4px;
  }

  &__icon {
    display: inline-flex;
    width: 24px;
    height: 24px;
  }
}
