@import './normalize';
@import './reset.local';
@import './fonts';
@import './typography';
@import './variables';

:root {
  --primary-color: #1071ff;
  --primary-color-rgb: 16, 133, 255;
  --primary-light: #bcd7ff;

  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 16px;

  background-color: $black;
  color: $dark;
}

body {
  -webkit-font-smoothing: antialiased; // параметр меняет сглаживание шрифта на Mac OS (для Chrome)
  -moz-osx-font-smoothing: grayscale; // параметр меняет сглаживание шрифта на Mac OS (для Firefox)
}
