/* ----------------
primary-color main
----------------- */
$primary-color: var(--primary-color);
$primary-color-rgb: var(--primary-color-rgb);
$primary-light: var(--primary-light);
$primary-hover: rgba($primary-color-rgb, 0.08);
$primary-active: rgba($primary-color-rgb, 0.16);
$primary-fill: #222222;

$dark: #050037; //Space

/* ----------------
primary-color other
---------------- */

$dark--8: rgba($dark, 0.08);
$dark--16: rgba($dark, 0.16);
$dark--30: rgba($dark, 0.3);
$dark--50: rgba($dark, 0.5);
$dark--70: rgba($dark, 0.7);

$black--2: rgba(0, 0, 0, 0.02);
$black--4: rgba(0, 0, 0, 0.04);
$black--5: rgba(0, 0, 0, 0.05);
$black--8: rgba(0, 0, 0, 0.08);
$black--8-static: #ebebeb;
$black--12: rgba(0, 0, 0, 0.12);
$black--16: rgba(0, 0, 0, 0.16);
$black--22: rgba(0, 0, 0, 0.22);
$black--24: rgba(0, 0, 0, 0.24);
$black--38: rgba(0, 0, 0, 0.38);
$black--54: rgba(0, 0, 0, 0.54);
$black--64: rgba(0, 0, 0, 0.64);
$black--87: rgba(0, 0, 0, 0.87);
$black: #000000;
$dark-gray: #454545;

$white: #ffffff;
$white--8: rgba(255, 255, 255, 0.08);
$white--16: rgba(255, 255, 255, 0.16);
$white--30: rgba(255, 255, 255, 0.3);
$white--50: rgba(255, 255, 255, 0.5);
$white--70: rgba(255, 255, 255, 0.7);

/* ----------------
common-color main
----------------- */
$modal-shadow: 0 0 0 1px $black--8, 0 3px 8px $black--8; //Общая тень модалов
$focus-shadow: 0 0 0 2px #{rgba($primary-color-rgb, 0.24)}; //Выделение для фокуса
$secondary-fill: var(--secondary-fill, #f2f2f2); // black--8-static Общая заливка

/* ----------------
secondary-color

second-color-[близкий цвет][порядковый номер]
r - red
b - blue
g - green
y - yellow
---------------- */
$second-color-r1: #ff6272; //Mars
$second-color-r1-light: #ffd3d8; //Mars light
$second-color-r2: #ff9fb8; //Pluto
$second-color-r2-light: #ffe4eb; //Pluto light
$second-color-r3: #d93fff; //Callisto
$second-color-r3-light: #f4c9ff; //Callisto light

$second-color-g1: #00c164; //Titanium
$second-color-g1-light: #b8eed4; //Titanium light
$second-color-g2: #00e3ce; //Tethys
$second-color-g2-light: #b8f7f1; //Tethys light

$second-color-b1: #8a64fe; //Charon
$second-color-b1-light: #ded4ff; //Charon light
$second-color-b2: #00b7f1; //Uranus
$second-color-b2-light: #b8ebfb; //Uranus light
$second-color-b4: #0052f2; //Dione
$second-color-b4-light: #b8cffb; //Dione light
$second-color-y1: #fbc400; //Sun
$second-color-y1-light: #feeeb8; //Sun light
$second-color-y2: #fb8500; //Venus
$second-color-y2-light: #feddb8; //Venus light

$transparent: rgba(255, 255, 255, 0);
