@mixin typo-note {
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
}

@mixin typo-note-accent {
  font-family: 'Gilroy Bold', Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
}

@mixin typo-secondary-lines {
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 14px;
  line-height: 27px;
  font-weight: normal;
}

@mixin typo-secondary-high {
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
}

@mixin typo-secondary {
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
}

@mixin typo-body-high {
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: normal;
}

@mixin typo-body {
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
}

@mixin typo-h4 {
  font-family: 'Gilroy Bold', Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
}

@mixin typo-h3 {
  font-family: 'Gilroy Bold', Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

@mixin typo-h2 {
  font-family: 'Gilroy Bold', Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}

@mixin typo-h1 {
  font-family: 'Gilroy Bold', Arial, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
}

@mixin typo-title {
  font-family: 'Cervino Bold', Arial, sans-serif;
  font-size: 56px;
  line-height: 60px;
  font-weight: bold;
  text-transform: uppercase;
}
