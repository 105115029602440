@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src:
    local('Gilroy'),
    url('../assets/fonts/Gilroy/gilroy-medium.woff2') format('woff2'),
    url('../assets/fonts/Gilroy/gilroy-medium.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy Bold';
  font-style: normal;
  font-weight: bold;
  src:
    local('Gilroy'),
    url('../assets/fonts/Gilroy/gilroy-bold.woff2') format('woff2'),
    url('../assets/fonts/Gilroy/gilroy-bold.woff') format('woff');
}
