.app-main {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__container {
    flex-grow: 1;
    display: flex;
    gap: 16px;
    padding: 0 16px 16px;
    overflow: hidden;
  }

  &__chat {
    flex-grow: 1;
    max-width: 50%;
    margin: 0 auto;
  }

  &__activity {
    flex-shrink: 0;
  }
}

.activity-slide {
  &-enter {
    width: 0;
    opacity: 0;
  }

  &-enter-active {
    width: 65%;
    opacity: 1;
    transition: width 300ms ease-in-out, opacity 300ms ease-in-out;
  }

  &-enter-done {
    width: 65%;
  }

  &-exit {
    width: 65%;
    opacity: 1;
  }

  &-exit-active {
    width: 0;
    opacity: 0;
    transition: width 300ms ease-in-out, opacity 300ms ease-in-out;
  }
}
