@import '../../../styles/variables';
@import '../../../styles/typography';

.brainstorm {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  height: 100%;

  &__avatar {
    position: absolute;
    z-index: 9999;
    right: 8px;
    top: 8px;
  }

  &__mindmap {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    overflow: auto; // In case the mindmap is larger than the container
  }

  #mindmap {
    scale: 0.9;

    .mindmap-edges path {
      stroke: #BCD7FF !important;
      stroke-width: 5px !important;
    }

    .mindmap-node {
      rect.node-bkg {
        fill: #BCD7FF !important;
        rx: 4px;
        ry: 4px;
      }

      .text-inner-tspan {
        fill: #050037 !important;
      }
    }

    g.current {
      rect.node-bkg {
        fill: #8A64FE !important;
        rx: 4px;
        ry: 4px;
      }
      .text-inner-tspan {
        fill: #fff !important;
      }
    }
  }
}