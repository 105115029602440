@import '../../../styles/variables';
@import '../../../styles/typography';

.roleplay {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  height: 100%;

  &__avatar {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  &__footer {
    margin-top: auto;
  }

  &__legend {
    padding-right: 188px;
    font-size: 14px;
  }
}
