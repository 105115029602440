@import '../../../styles/variables';
@import '../../../styles/typography';

.voice-message {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__btn {
    align-self: center;
  }

  &__input {
    padding: 12px 16px;
    min-height: 40px;
    width: 100%;
    background-color: $black--8;
    border-radius: 20px;

    @include typo-secondary;
  }

  &__typing-dots {
    display: inline-block;
    white-space: pre; /* Сохраняет пробелы и переносы строк */

    @include typo-secondary;

    &::after {
      content: '...';
      display: inline-block;
      width: 1em;
      text-align: left;
      animation: dots 1.5s steps(4, end) infinite;
    }
  }
}

@keyframes dots {
  0%, 20% {
    content: '';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%, 100% {
    content: '...';
  }
}
