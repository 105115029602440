@import '../../styles/variables';
@import '../../styles/typography';

.header {
  padding: 12px 16px;

  &__info {
    padding: 8px;
  }

  &__title {
    color: $white;

    @include typo-h3;
  }
}
