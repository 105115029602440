@import '../../../styles/variables';
@import '../../../styles/typography';

.message {
  padding: 12px 16px;
  border-radius: 20px;

  @include typo-secondary;

  &--user {
    background-color: $primary-light;
    border-bottom-right-radius: 4px;
  }

  &--assistant {
    border: 1px solid $black--8;
    border-bottom-left-radius: 4px;
  }

  &__typing-dots {
    display: inline-block;
    white-space: pre; /* Сохраняет пробелы и переносы строк */

    @include typo-secondary;

    &::after {
      content: '...';
      display: inline-block;
      width: 1em;
      text-align: left;
      animation: dots 1.5s steps(4, end) infinite;
    }
  }
}

@keyframes dots {
  0%, 20% {
    content: '';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%, 100% {
    content: '...';
  }
}
