@import '../../styles/variables';
@import '../../styles/typography';

.activity-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: 8px;

  &__header {
    padding: 10px 16px;
    border-bottom: 1px solid $black--8;
  }

  &__wrapper {
    padding: 8px 0;
  }

  &__title {
    @include typo-h2;
  }

  &__container {
    flex-grow: 1;
  }
}
