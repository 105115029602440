@import '../../../styles/variables';
@import '../../../styles/typography';

.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px $black--12, 0 3px 8px 0 $black--22;
  background-color: $white;
  overflow: hidden;

  &__loading {
    position: absolute;
    left: auto;
    right: auto;
  }

  &__view {
    display: block;
    height: 100%;
    width: 100%;
  }
}
